import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

export default function ContactPage() {
  return (
    <Layout>
      <h1>Contact</h1>
      <p>
        If you want to get in touch with me, you may do so via any of my social
        accounts, listed on the
        {' '}
        <Link to="/about">about page</Link>
        . Or send me an e-mail at the following address.
      </p>
      <p>
        <em>I&apos;m currently only available for online consultation.</em>
      </p>
      <p>
        Daniel Kovacs, KDE Design - Budapest, Hungary |
        {' '}
        <a href="mailto:kovacsemod@gmail.com">kovacsemod@gmail.com</a>
      </p>
    </Layout>
  );
}
